import { store } from "react-notifications-component"

import { CATEGORIES, ROUTES } from "./variables"

export const get = (obj, path, defaultValue) => {
    const travel = regexp =>
        String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce(
                (res, key) =>
                    res !== null && res !== undefined ? res[key] : res,
                obj
            )
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
    return result === undefined || result === obj ? defaultValue : result
}

export const getCategory = category => {
    let result = CATEGORIES.ARCH

    Object.keys(CATEGORIES).forEach(key => {
        if (key === category) result = CATEGORIES[key]
    })

    return result
}

export const getRouteByCategory = category => {
    if (category === CATEGORIES.INTER) return ROUTES.INTER
    if (category === CATEGORIES.CONCEPT) return ROUTES.CONCEPT
    return ROUTES.ARCH
}

export const splitStringFromJSON = str => {
    if (!Boolean(str)) return []
    return str.split("\n")
}

/**
 * @param {string} type - type: success | danger | info | default | warning
 * @param {string} title
 * @param {string} msg
 * @param {boolean} isMobile
 */
export const notify = (type, title, msg, isMobile) => {
    store.addNotification({
        title: title,
        message: msg,
        type: type,
        isMobile: isMobile,
        insert: "bottom-left",
        container: "bottom-left",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
        },
    })
}
